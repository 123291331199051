import React, { useState, useEffect } from "react";
import "../CSS/Intro.css";
import portfoliologo from "../Images/portfoliologo.svg";
import { useNavigate } from "react-router-dom";
import sec1 from "../Images/sec1.svg";
import sec2 from "../Images/sec2.svg";
import sec3 from "../Images/sec3.svg";
import whiteLogo from "../Images/whiteLogo.svg";
import { Modal, Button } from "react-bootstrap";
import Privacypolicy from "./Privacypolicy";
import { baseURL } from "./Apis";
import whitelogo from "../Images/White logo.svg";
import "@fontsource/bebas-neue";
import "@fontsource/work-sans";
import img1 from "../Images/photo1.svg";
import photo4 from "../Images/photo4.svg";
import upload from "../Images/upload.svg";
import chat from "../Images/chat.svg";
import ans from "../Images/ans.svg";
import mask1 from "../Images/mask1.svg";
import mask2 from "../Images/mask2.svg";
import eye from "../Images/eye.svg";
import box from "../Images/box.svg";
import sparkle from "../Images/sparkle.svg";
import swatch from "../Images/swatch.svg";
import card1 from "../Images/card1.svg";
import card2 from "../Images/card2.svg";
import card3 from "../Images/card3.svg";
import card4 from "../Images/card4.svg";
import card5 from "../Images/card5.svg";
import card6 from "../Images/card6.svg";
import card7 from "../Images/card7.svg";
import hand2 from "../Images/hands2.svg";
import circles from "../Images/circles.svg";
import linkedin from "../Images/linkedinicon.svg";
import loginicon from "../Images/loginicon.svg";
import player from "../Images/Player.svg";
import ReactPlayer from "react-player";
import Snackbar from '@mui/material/Snackbar';
import { LuRefreshCcw } from "react-icons/lu";


const Introduction = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const onClickLogin = () => {
    navigate("/login");
  };

  const onClickRegister = () => {
    navigate("/register");
  };
  const [formValues, setFormValues]: any = useState({
    Company: "",
    'First Name': "",
    'Last Name': "",
    Designation: "",
    Email: "",
    Phone:'',
    Description:''
  });
  const initialValues = {
    Company: "",
    'First Name': "",
    'Last Name': "",
    Designation: "",
    Email: "",
    Phone: "",
    Description: ""
  };
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState<string>("");
  const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [disableButton, setdisableButton] = React.useState(false);
  const [captcha, setCaptcha] = useState<string>("");
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues:any) => ({
        ...prevValues,
        [name]: value,
    }));
};



useEffect(() => {
  const loadZohoScript = () => {
    var $zoho = (window as any).$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: 'siq35e638448267c14646305d5305ef278a79d151f4fa755ac8add354b00d63568bb7aad3912cdc067818239ad075c7eabb',
      values: {},
      ready: function () {},
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zoho.com/widget";
    const t:any = document.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(script, t);

    // After script loads, track the visitor and fetch the unique visitor ID
    script.onload = () => {
      if ($zoho && $zoho.salesiq && $zoho.salesiq.visitor) {
        // Set unique visitor ID
        const uniqueVisitorId = $zoho.salesiq.visitor.uniqueid();
        setUniqueId(uniqueVisitorId); // Set unique ID in state
      }
    };
  };

 // loadZohoScript();
}, []);



  // Handle form submission
  const handleSubmit = async(e: any) => {
    e.preventDefault();
    if (!checkMandatory()) return;
    const form = e.target;
    const formData = new FormData(form);
    const dataForAPi: any = {
      company: formValues.Company,
      title: formValues.Designation,
      first_name: formValues['First Name'],
      last_name: formValues['Last Name'],
      email: formValues.Email,
      phone_no: formValues.Phone,
      description: formValues.Description,
    };
    setdisableButton(true);
    try {
      const response = await fetch(baseURL+'send_lead_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers required by the API
      },
        body: JSON.stringify(dataForAPi),
      });
      if (response.ok) {
        setFormValues(initialValues);
        setdisableButton(false);
        setDigContent(
          <>
            <p style={{ marginBottom: '5px' }}>
              <span 
                id="successicon" 
                style={{
                  background: 'url(https://static.zohocdn.com/crm/CRMClient/images/successalert_a8be6d090fa10db5a38d8f87bf194f7d_.svg) no-repeat 0 0',
                  width: '22px',
                  height: '22px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              ></span>&nbsp;&nbsp;
              <b>Thank you for submitting your response.</b>
            </p>
            <p>
              <span>Your response has been submitted successfully. We will reach out to you soon.</span>
            </p>
          </>
        );
        
        setModalShow(true)
      } else {
        setdisableButton(false);
        setDigContent(`Form submission failed. Please try again.`);
        setModalShow(true)
      }
    } catch (error) {
      setdisableButton(false);
      setDigContent(`Form submission failed. Please try again.`);
      setModalShow(true)
    } 

   /*  try {
      const response = await fetch('https://crm.zoho.com/crm/WebToLeadForm', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setDigContent(`Thank you for submitting your response. Your response has been submitted successfully. We will reach out to you soon.`);
        setModalShow(true)
      } else {
        setDigContent(`Form submission failed. Please try again.`);
        setModalShow(true)
      }
    } catch (error) {
      setDigContent(`Form submission failed. Please try again.`);
      setModalShow(true)
    }  */
  
    /* const submitButton = document.querySelector('.formsubmit');
    if (submitButton) {
      submitButton.setAttribute('disabled', 'true');
    }  */
    //window.addEventListener('focus', historyBack); */
    /* try {
      if (window && (window as any).$zoho && (window as any).$zoho.salesiq) {
        const $zoho = (window as any).$zoho;
        if (formValues.Email) {
          $zoho.salesiq.visitor.email(formValues.Email);
        }
      }
    } catch (error) {
      
    } */
  };

  const historyBack = () =>{
    const submitButton = document.querySelector('.formsubmit');
    if (submitButton) {
      submitButton.removeAttribute('disabled');
    }
   reloadCaptcha(); 
   window.removeEventListener('focus', historyBack); 
  }
  
  const checkMandatory = () => {
    const mndFields: any = [
      "Company",
      "First Name",
      "Last Name",
      "Designation",
      "Email",
    ];
    for (let i = 0; i < mndFields.length; i++) {
      const fieldValue: any = formValues[mndFields[i]];
      if (!fieldValue.trim()) {
        setDigContent( <>
          Fields marked (<span style={{ color: "#08B180" }}>*</span>) are mandatory.
        </>);
        setModalShow(true)
        return false;
      }
    }
    return validateEmail();
  };


  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formValues.Email)) {
      setDigContent("Please enter a valid email.");
      setModalShow(true)
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptcha(e.target.value);
  };

  const reloadCaptcha = () => {
    var captcha:any = document.getElementById('captchaImage'); 
    if(captcha.src.indexOf('&d') !== -1) 
    { 
        captcha.src = captcha.src.substring(0, captcha.src.indexOf('&d')) + '&d' + new Date().getTime(); 
    } else 
    { 
        captcha.src = captcha.src + '&d' + new Date().getTime(); 
    } 
  };

  const handleStartClick = () => {
    const element = document.getElementById("zohoform");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>

 {/* Header ======================================================================================= */}

    <div className="header-container">
      
      <img src={whitelogo} alt="Logo" className="logo" />

     
      <div className="right-container">
        <div className="login" onClick={onClickLogin}>
          <img src={loginicon} alt="Login Icon" className="login-icon" />
          Login
        </div>
        <button className="get-started-btn" onClick={handleStartClick}>
          Get Started
        </button>
      </div>
    </div>


 {/* Section 1 ======================================================================================= */}

    <div className="sec1">
      <div className="sec1-title">
        <div>FUTURE PROOF Your Commercial</div>
        <div>
          Real Estate with <span className="sec1-highlight">Portfolio Insights</span>
        </div>
      </div>
      <div className="sec1-subtitle">
        Minimize Risk and Maximize ROI with AI-Powered Analytics
      </div>
      <div className="sec1-button-wrapper">
        <button className="sec1-btn" onClick={handleStartClick}>
          Get Started
        </button>
      </div>
      <img className="sec1-img" src={img1} alt="Section background" />
    </div>


 {/* Section 2 ======================================================================================= */}
 <div className="sec2">
  <div className="sec2-headline">
    80% of Workplaces Around the World Are Now Hybrid.
  </div>
  <div>
    <div className="sec2-cta-button px-2">
      Is Your Business Strategy Keeping Pace?
    </div>
  </div>
  <div className="sec2-subtext">
  <div>
    With 24x7 access to property data, actionable insights, and powerful analytics,
    Portfolio One empowers
  </div>
  <div >
    Senior Leaders to make informed decisions that drive efficiency, reduce costs,
    and 3x employee experience.
  </div>
  </div>
  <div className="sec2-video-container">
    <div className="sec2-video-wrapper">
      <ReactPlayer
        url="https://www.youtube.com/watch?v=qjVdpuVS4A8"
        width="100%"
        height="100%"
        controls={true}
      />
    </div>
  </div>
</div>

 

 {/* Section 3 ======================================================================================= */}

 <div className="sec3">
      <div className="sec3-row">
        <div className="sec3-image-container">
          <img src={photo4} alt="" className="sec3-image" />
        </div>
        <div className="sec3-text-container">
          <div className="sec3-title">Meet your AI-powered</div>
          <div className="sec3-subtitle">real estate portfolio assistant</div>
          <div className="sec3-highlight-text greenBackgroundTransperant">
            <img src={sparkle} alt="" /> Introducing Portfolio One’s AI-powered Chatbot
          </div>
          <div className="sec3-description">
            Your go-to resource for real-time insights into physical asset usage, resource allocation, and more.
            Just ask a question, and the chatbot will provide accurate and informative answers based on your
            previously uploaded data.
          </div>
        </div>
      </div>
      <span className="sec3-instruction greenBackgroundTransperant">
        <img src={sparkle} alt="" /> How to use the AI-powered Chatbot?
      </span>
      <div className="sec3-steps">
        <div className="card sec3-card">
          <div className="sec3-card-body">
            <div className="sec3-card-row">
              <div className="sec3-icon">
                <img src={upload} alt="" className="sec3-icon-image" />
              </div>
              <div className="sec3-step-details">
                <div className="sec3-step">Step 1</div>
                <div className="sec3-step-name">Upload</div>
                <hr className="sec3-divider" />
                <div className="sec3-step-description">
                  Upload Your Documents in the Portfolio One Server
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card sec3-card">
          <div className="sec3-card-body">
            <div className="sec3-card-row">
              <div className="sec3-icon">
                <img src={chat} alt="" className="sec3-icon-image" />
              </div>
              <div className="sec3-step-details">
                <div className="sec3-step">Step 2</div>
                <div className="sec3-step-name">Chat</div>
                <hr className="sec3-divider" />
                <div className="sec3-step-description">
                  Speak or Type Your Question into The AI Chatbot
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card sec3-card">
          <div className="sec3-card-body">
            <div className="sec3-card-row">
              <div className="sec3-icon">
                <img src={ans} alt="" className="sec3-icon-image" />
              </div>
              <div className="sec3-step-details">
                <div className="sec3-step">Step 3</div>
                <div className="sec3-step-name">Answers</div>
                <hr className="sec3-divider" />
                <div className="sec3-step-description">
                  Get Instant, Clear & Accurate Answers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="sec3-demo-button " onClick={handleStartClick}>
        Book a Free Demo
      </button>
    </div>

 {/* Section 4 ======================================================================================= */}
 <div className="sec4">
    <div className="sec4-header">
      <div className="sec4-title sec4-highlight">
        Portfolio One Is Your{" "}
        <span className="sec4-highlight">Strategic Co-Pilot</span>
      </div>
      <div className="sec4-subtitle">for physical asset Management</div>
      </div>
      <div className="sec4-flex-container">
        <div>
          <img src={mask1} alt="Mask 1" className="sec4-mask" />
        </div>
        <div className="sec4-column">
          <img src={box} alt="Box" className="sec4-icon" />
          <div className="sec4-heading">
            Get A Clear View of Your <div>Real Estate Portfolio</div>
          </div>
          <div className="sec4-description">
            Our centralized platform offers comprehensive insights into your
            current operations. From performance metrics to future
            projections, our advanced tools give you exactly the right data
            for informed decision-making.
          </div>
          <button className="sec4-button" onClick={handleStartClick}>
            Get Started
          </button>
        </div>
      </div>

      <div className="sec4-gap">
        <div>
          <img src={mask2} alt="Mask 2" className="sec4-mask"/>
        </div>
        <div className="sec4-column">
          <img src={eye} alt="eye" className="sec4-icon" />
          <div className="sec4-heading">
          Predict The Future <div>with Accuracy</div>
          </div>
          <div className="sec4-description">
          Our advanced forecasting tools help you anticipate trends and
            create various scenarios for Senior Leaders to make strategic
            decisions that drive long-term success.
          </div>
          <button className="sec4-button" onClick={handleStartClick}>
            Get Started
          </button>
        </div>
      </div>
    </div>


{/* Section 5 ======================================================================================= */}
<div className="sec5">
      <div className="sec5-title">
        Features That <span className="highlight">Set Us Apart</span>
      </div>
      <div className="sec5-card-container">
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card1} className="card-image-content" />
            </div>
            <div className="card-title">Resource Demand Forecasting</div>
            <div className="card-description">
              Our advanced algorithms predict real estate demand, helping you anticipate future needs.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card2} className="card-image-content" />
            </div>
            <div className="card-title">Portfolio Optimization</div>
            <div className="card-description">
              Receive personalized recommendations to optimize your real estate for cost-effectiveness, location, and resource availability.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card3} className="card-image-content" />
            </div>
            <div className="card-title">Intuitive Data Visualization</div>
            <div className="card-description">
              Gain valuable insights with our intuitive charts, graphs, and maps that bring market trends and portfolio performance to life.
            </div>
          </div>
        </div>
      
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card4} className="card-image-content" />
            </div>
            <div className="card-title">Customizable Dashboards</div>
            <div className="card-description">
              Personalize your dashboard to your specific needs, KPIs, and OKRs for streamlined decision-making.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card5} className="card-image-content" />
            </div>
            <div className="card-title">Collaboration Tools</div>
            <div className="card-description">
              Share insights, analysis, and reports seamlessly with your team, fostering collaboration and alignment.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card6} className="card-image-content" />
            </div>
            <div className="card-title">Hassle-Free Integration</div>
            <div className="card-description">
              Seamlessly integrate Portfolio One with your existing enterprise systems for efficient data management.
            </div>
          </div>
        </div>
  
        <div className="card cardType3">
          <div className="card-body">
            <div className="card-image" style={{ backgroundImage: `url(${swatch})` }}>
              <img src={card7} className="card-image-content" />
            </div>
            <div className="card-title">100% Data Security and Compliance</div>
            <div className="card-description">
              Rest assured that your sensitive information is safeguarded with our strict security measures and compliance standards.
            </div>
          </div>
        </div>
      </div>
    </div>



{/* Section 6 ======================================================================================= */}

    <div className="sec6">
    <div className="sec6-container" style={{ backgroundImage: `url(${circles})` }}>
        <img src={hand2} alt="" className="sec6-image" />
        <div className="sec6-text">
          <div className="sec6-title">Optimize Your Physical Asset Strategy</div>
          <div className="sec6-highlight">with Smart AI-Powered Analytics.</div>
          <div className="sec6-subtitle">Do More, with Portfolio One.</div>
        </div>
      </div>

      <div className="sec6-getintouch">
        Get in Touch
      </div>
      <div id="zohoform"></div>
      <form
        id="webform5957627000004610001"
        onSubmit={handleSubmit}
        name="WebToLeads5957627000004610001"
      >
        <input
          type="text"
          style={{ display: "none" }}
          name="xnQsjsdp"
          defaultValue="7bb935520832322811b7e9ac6aee49bdd670a0c3b801c8365e2f7a811d356320"
          readOnly
        />
        <input type="hidden" name="zc_gad" id="zc_gad" defaultValue=""></input>
        <input
          type="text"
          style={{ display: "none" }}
          name="xmIwtLD"
          defaultValue="55f3a6fbc602887c4c636a3056ebf82567bafe9a9ae5ca2d1308ab94535657d2973b1f7470a0334469083564134321b8"
          readOnly
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="actionType"
          defaultValue="TGVhZHM="
          readOnly
        />
       
        <input
          type="text"
          style={{ display: "none" }}
          id="ldeskuid"
          name="ldeskuid"
          readOnly
        />
        <input
          type="text"
          style={{ display: "none" }}
          id="LDTuvid"
          name="LDTuvid"
          value={uniqueId}
          readOnly
        />
        <div className="sec6-form-container">
  <div className="sec6-form-group">
    <label htmlFor="First Name" className="sec6-form-label">
      First Name <span className="sec6-required">*</span>
    </label>
    <input
      type="text"
      className="form-control sec6-inputInitialForm"
      id="First Name"
      name="First Name"
      value={formValues.firstName}
      onChange={handleChange}
    />
  </div>
  <div className="sec6-form-group">
    <label htmlFor="Last Name" className="sec6-form-label">
      Last Name <span className="sec6-required">*</span>
    </label>
    <input
      type="text"
      className="form-control sec6-inputInitialForm"
      id="Last Name"
      name="Last Name"
      value={formValues.lastName}
      onChange={handleChange}
    />
  </div>
</div>
<div className="sec6-form-container">
          <div className="sec6-form-group">
            <div
              className="sec6-form-label"
            >
              Email <span className="sec6-required">*</span>
            </div>

            <input
              type="text"
              className="form-control sec6-inputInitialForm"
              id="Email"
              name='Email'
              value={formValues.email}
              onChange={handleChange}
            />
            <div></div>
          </div>
          <div className="sec6-form-group">
            <div
              className="sec6-form-label"
            >
              Phone
            </div>

            <input
              type="text"
              className="form-control sec6-inputInitialForm"
              id="Phone"
              name='Phone'
              value={formValues.phone}
              onChange={handleChange}
            />
            <div></div>
          </div>
        </div>
        <div
          className="sec6-form-container"
        >
          <div className="sec6-form-group">
            <div
              className="sec6-form-label"
            >
              Company <span className="sec6-required">*</span>
            </div>

            <input
              type="text"
              className="form-control sec6-inputInitialForm"
              id="Company"
              name='Company'
              value={formValues.company}
              onChange={handleChange}
            />
            <div></div>
          </div>
          <div className="sec6-form-group">
            <div
              className="sec6-form-label"
            >
              Job Title <span className="sec6-required">*</span>
            </div>

            <input
              type="text"
              className="form-control sec6-inputInitialForm"
              id="Designation"
              name='Designation'
              value={formValues.designation}
              onChange={handleChange}
            />
            <div></div>
          </div>
        </div>
        <div
          className="sec6-form-container"
        >
          <div className="sec6-form-grouptext">
            <div
              className="sec6-form-label" style={{textAlign:'left'}}
            >
              Message
            </div>

            <textarea
              className="form-control sec6-inputtextarea"
              id="Description"
              name='Description'
              value={formValues.Description}
              onChange={handleChange}
              rows={3}
            />
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ textAlign: "left" }}>

          <div className="zcwf_row">
            <div className="zcwf_col_fld">
              <img id="captchaImage" src='https://crm.zoho.com/crm/CaptchaServlet?formId=55f3a6fbc602887c4c636a3056ebf82567bafe9a9ae5ca2d1308ab94535657d2973b1f7470a0334469083564134321b8&grpid=7bb935520832322811b7e9ac6aee49bdd670a0c3b801c8365e2f7a811d356320' alt="Captcha" />
              <LuRefreshCcw onClick={reloadCaptcha} style={{marginLeft:'20px', color:'#08B180', cursor:'pointer',  
      height: "30px", 
      width: "30px"}}/>
              
            </div>
          </div>
           
          </div>
  
        </div>
        <div
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ textAlign: "left" }}>

        <input
              style={{
                width: "300px",
                marginTop: "15px",
                height: "62px",
                border: "solid 1px #252525",
                borderRadius: "6px",
                backgroundColor: "#0D0D0D",
                padding: "19px 24px",
                color: "#393939",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
              className="form-control sec6-inputInitialForm"
              type='text'
              id="enterdigest"
              aria-labelledby="reCaptchaField"
              placeholder="Enter the Captcha"
                aria-valuemax={10}
                maxLength={10}
                name="enterdigest"
                value={captcha}
                onChange={handleInputChange}
            />
            </div>
            </div>
        <div
          className="sec6-form-container"
        >
          <button
    type="submit"
    id="formsubmit"
    value="Submit"
    aria-label="Submit"
    title="Submit"
    className="sec6-submit-button"
    disabled={disableButton}
  >
    Submit
  </button>
        </div>
        <div className="sec6-footer">
  <div className="sec6-footer-content">
    <div className="sec6-logo-container">
      <img src={whitelogo} alt="" className="sec6-logo" />
      <div className="sec6-privacy-policy" onClick={handleShow}>
        Privacy Policy
      </div>
    </div>
    <div className="sec6-linkedin-container">
      <img src={linkedin} alt="" className="sec6-linkedin-logo" />
    </div>
  </div>
  <div className="sec6-divider"></div>
  <div className="sec6-copyright">
    Copyright © 2023 Digital Finance Transformation and contributors
  </div>
</div>
{/*         <script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=2e28fa9e48636681b52bbbdffdfce82ecdb2bfa9063e14d5f7fbf20bb8aaf6de3f2adbd143072ddc37aa79307b1246c8gid22177ee2bfc97ffefe7a0fbbc885ca7afd99d332cca4fb5926ff7d91bc985142gid6ee2438d954908a336338d8d580fa82a1f0751d52135df5c666b2e813b652becgidc1abf06de2014fa7737bb781fdb57e76c88fe5c6b00c1175a5c9308918ebce5b&tw=611731083142a05e2b7a00c02b810fbb418d18c0a6ef82427015d744305fcdee'></script>
 */}       
 {/*  <script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=6e7f1f541b192dad98730ec5b61e8a1a2e37e3b68bbce78b300cc722520495ae5c4b6a0cf2f3d6527e7f83c857662e63gid175946c18a74e0ba369e0223ed1440117cba095cb4c30c290d25a88f5f84284cgid247f794b5aa6c59b67730338bb227731f7aea1919e421eadce75933941055a95gidc5942e066d276ab00bbfba2733dc6e2f509d7b16cfb88ad70633a0ccdcbb8c26&tw=c26e9295dfee170ff00fe58031775f74ad40a9c04b951f324f021fe5ba58b3fc'></script>
       */}
      </form>
    </div>
    
    <Snackbar
    anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
    open={modalShow}
    autoHideDuration={5000}
    onClose={() => setModalShow(false)}
    message={digContent}
    ContentProps={{
      className: 'notifyBar1'
    }}
    key={'top' + 'center'}
    style={{marginTop:'100px'}}
  />

<Privacypolicy closeModal={handleClose} openModal={showModal} />
  </>
  );
};

export default Introduction;
