import react,{useState} from 'react';
import portfoliologo from '../Images/portfoliologo.png';
import earth from '../Images/earth.png';
import tog from '../Images/Vector.png';
import multiusers from '../Images/multiusers.svg';
import '../CSS/navigation.css';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { LuLayoutDashboard } from "react-icons/lu";
import { FiPieChart } from "react-icons/fi";
import { TbLayoutSidebar } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { SlDrawer } from "react-icons/sl";
import { LiaCrownSolid } from "react-icons/lia";
import { FiUpload } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { LuUsers } from "react-icons/lu";
import { BsGlobeAmericas } from "react-icons/bs";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import { VscOrganization } from "react-icons/vsc";
import { SlOrganization } from "react-icons/sl";
import { VscFeedback } from "react-icons/vsc";
import { RiQuestionAnswerLine } from "react-icons/ri";

const Sidebarnav =({activeSection, onSectionChange}:any)=>{
  const navigate = useNavigate();
  var userRoleId:any=sessionStorage.getItem('userRoleId')
  const [collapse,setCollapse]=useState(true);
  const toggleSidebar=()=>{
    setCollapse(!collapse)
    const myElement = document.getElementById('sidebarnav');
    if (myElement) {
        myElement.classList.toggle('close');
    }
  }
    
return(
<>
    <nav id='sidebarnav' className="sidebarnav close">
      <header>
      <div className="image-text">
        <span className="image">
          <img src={collapse?earth:portfoliologo} alt="" />
        </span>
      </div>
      <TbLayoutSidebar className="toggle" onClick={toggleSidebar} style={{height:"25px",width:"25px"}}/>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
           {/*  <li className={`nav-link ${activeSection!='dashboard' && activeSection!='home'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/dashboard');onSectionChange('dashboard')}} >
              <div className="icon">
                <LuLayoutDashboard />
              </div>
            <span className={`text nav-text` } >Overview</span>
            </li>
            <li className={`nav-link ${activeSection!='report'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/report');onSectionChange('report')}}>
            <div className="icon">
            <FiPieChart />
              </div>
            <span className="text nav-text"  >Report</span>
            </li> */}
            <li className={`nav-link ${activeSection!='siteList' && activeSection!='home'?'':'activeNavbar'}`}  onClick={()=>{navigate('/home/siteList');onSectionChange('siteList')}}>
            <div className="icon">
                <BsGlobeAmericas />
              </div>
            <span className={`text nav-text`} >Site List</span>
            <span className="tooltip text nav-text">Site List</span>
            </li>
           
            {userRoleId!=='1'?
            <li className={`nav-link ${activeSection!='subscription'?'':'activeNavbar'}`}  onClick={()=>{navigate('/home/subscription');onSectionChange('subscription')}}>
            <div className="icon">
                <LiaCrownSolid />
              </div>
            <span className="text nav-text"  >Subscription</span>
            <span className="tooltip text nav-text">Subscription</span>
            </li>:null}
            
            <li className={`nav-link ${activeSection!='userManagement'?'':'activeNavbar'}`}  onClick={()=>{navigate('/home/userManagement');onSectionChange('userManagement')}}>
            <div className="icon">
                <LuUsers />
              </div>
            <span className="text nav-text"  >User Management</span>
            <span className="tooltip text nav-text">User Management</span>
            </li>

            {userRoleId=='3'?
            <li className={`nav-link ${activeSection!='roleManagement'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/roleManagement');onSectionChange('roleManagement')}}>
            <div className="icon">
                <VscOrganization />
              </div>
            <span className="text nav-text"  >Role Management</span>
            <span className="tooltip text nav-text">Role Management</span>
            </li>:null}

            {userRoleId=='3'?
            <li className={`nav-link ${activeSection!='orgManagement'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/orgManagement');onSectionChange('orgManagement')}}>
            <div className="icon">
                <SlOrganization />
              </div>
            <span className="text nav-text"  >Organization Management</span>
            <span className="tooltip text nav-text">Organization Management</span>
            </li>:null}

            {userRoleId!='1'?
            <li className={`nav-link ${activeSection!='headcountgrowth'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/headcountgrowth');onSectionChange('headcountgrowth')}}>
            <div className="icon">
                <GoGraph />
              </div>
            <span className="text nav-text"  >Headcount Growth</span>
            <span className="tooltip text nav-text">Headcount Growth</span>
            </li>:null}

            {userRoleId=='1'?
            <li className={`nav-link ${activeSection!='feedback'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/feedback');onSectionChange('feedback')}}>
            <div className="icon">
                <VscFeedback />
              </div>
            <span className="text nav-text"  >Customer Feedback</span>
            <span className="tooltip text nav-text">Customer Feedback</span>
            </li>:null}

            <li className={`nav-link ${activeSection!='setting'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/setting');onSectionChange('setting')}}>
            <div className="icon">
                <IoSettingsOutline />
              </div>
            <span className="text nav-text"  >Setting</span>
            <span className="tooltip text nav-text">Setting</span>
            </li>
            {userRoleId!=='1'?
            <li className={`nav-link ${activeSection!='uploadleasedocument'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/uploadleasedocument');onSectionChange('uploadleasedocument')}}>
            <div className="icon">
                <FiUpload />
              </div>
            <span className="text nav-text"  >Lease Document</span>
            <span className="tooltip text nav-text">Lease Document</span>
            </li> :null}

            <li className={`nav-link ${activeSection!='faq'?'':'activeNavbar'}`} onClick={()=>{navigate('/home/faq');onSectionChange('faq')}}>
            <div className="icon">
                <RiQuestionAnswerLine />
              </div>
            <span className="text nav-text"  >FAQ</span>
            <span className="tooltip text nav-text">FAQ</span>
            </li>
          </ul>
        </div>
      </div>
        
    </nav>
    
      </>
)}

export default Sidebarnav