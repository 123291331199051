import React, { useState } from "react";
import axios from 'axios';
import { encryptor } from "./Encryption";

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Define your API base URL
//export const baseURL = 'https://www.portfolioone.io/api/';
//export const clinetidsub = 'AT-n4aSrDD3mLpuwc_JG5_PR6dGMYfkpJTXCeZCU7ZVOT4ofN1IHzze4iCkCUYxcueKWNJTph7WZCYJX';
export const clinetidsub = 'test';
export const baseURL = 'https://dev.portfolioone.io/api/';
const authToken =
    sessionStorage.getItem("apiToken") === undefined || sessionStorage.getItem("apiToken") === null
      ? ""
      : sessionStorage.getItem("apiToken");
 
const authTokenTest =encryptor.decryptData(authToken,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');

// Define your commonly used APIs
export const loginUser = async (loginData:any) => {
  try {
    const response = await axios.post(`${baseURL}/login`,loginData,config);
    return response;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const findUserAccessRank =(subStatus:any,trialDays:any,sq_feet:any,docCount:any)=>{
  // 1 = Subscribed user
  // 2 = Unsubscribed user with trial days over
  // 3 = Unsubscribed user with trial days left 
  // 4 = Unsubscribed user with but can't add/update data due to conditions
  if(subStatus==='true'){
    return 1;
  }
  else{
    if(+trialDays<0){
      return 2;
    }else{
      if(+sq_feet<10000 && +docCount<5){
        return 3
      }
      else{
        return 4
      }
    }
  }
}

export const getUserRoleBasedAccess =async(userRoleId:any,org_id:any, d_token:any)=>{
  var permissions:any=[]
  var link = baseURL+ "getPermissionsByOrgId/"+org_id;
  await axios
    .get(link, {
      headers: {
        Authorization: `Bearer ${d_token}`,
      },
    })
    .then(async (response) => {
      response.data.data.map((val:any,key:any)=>{
        if(val.role_id==userRoleId){
          sessionStorage.setItem('permissionIDS',val.permission_ids)
        }
      })
      return permissions;
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      return permissions;
    });
}


