import React,{useState,useEffect} from "react";
import '../CSS/common.css';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import * as yup from 'yup';
import { RiPencilFill } from "react-icons/ri";
import axios from "axios";
import { RotatingLines } from 'react-loader-spinner';
import { baseURL } from "./Apis";
import { encryptor } from "./Encryption";

const validationSchema = yup.object().shape({
  headcount: yup.number().integer().positive().max(100).required()
});

const Headcountgrowth=()=>{
  const user_id:any =
  sessionStorage.getItem("user_id") === undefined
    ? 0
    : sessionStorage.getItem("user_id");
    const org_id:any =
    sessionStorage.getItem("org_id") === undefined
      ? 0
      : sessionStorage.getItem("org_id");
      const authTokenTest =   sessionStorage.getItem("apiToken") === undefined? 0: sessionStorage.getItem("apiToken");   
      const authToken =encryptor.decryptData(authTokenTest,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');
    const [showModal, setShowModal] = useState(false);
    const [year_hc,setYH]=useState('');
    const [hc,setHC]=useState('');
    const [hc_index,setIndexHC]=useState(0);
    const [hcError,setHCError]=useState(false);
    const [headcount,setHeadcount]:any=useState([]);
    const [rowData,setRowData]:any=useState([]);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const [loader, setLoader] = useState(false);

    useEffect(()=>{
      setLoader(true);
      var link = baseURL + "getUserHeadcount/"+user_id;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
        const { headcount1, headcount2, headcount3, headcount4 } = response.data.data;
        const newObject = {
          headcount1:headcount1 ?? 0,
          headcount2:headcount2 ?? 0,
          headcount3:headcount3 ?? 0,
          headcount4:headcount4 ?? 0
        };
        setHeadcount(newObject);
        setLoader(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        setLoader(false);
        });
        
    },[])

    const columnDefs=[
        {name:'Year',selector: (row:any) => row.year},
        {name:'Headcount Growth',selector: (row:any) => row.hc+'%'},
        {name:'Action',cell: (row:any,key:any) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <RiPencilFill className="" style={{color:"#0072B2",cursor:"pointer",height:"18px",width:"18px"}} aria-hidden="true" onClick={()=>{handleShow();setYH(row.year);setHC(row.hc);setIndexHC(key)}} />
            </div>
          ),}
    ]
  
    useEffect(()=>{
      setRowData([
        {year:moment().format('YYYY'),hc:headcount.headcount1!=undefined?headcount.headcount1:0},
        {year:moment().add(1, 'years').year(),hc:headcount.headcount2!=undefined?headcount.headcount2:0},
        {year:moment().add(3, 'years').year(),hc:headcount.headcount3!=undefined?headcount.headcount3:0},
        {year:moment().add(5, 'years').year(),hc:headcount.headcount4!=undefined?headcount.headcount4:0}
    ])
    },[headcount])

    const onCountChange=(e:any)=>{
      const input = e.target.value;
      const regex = /^[0-9]*$/;
      if(regex.test(input)){
      setHCError(false);
      setHC(e.target.value);}
    }

    const onSubmitData=async()=>{
      //console.log(rowData)
      setLoader(true);
      const hcData={
        headcount:hc
      }
      try{
        await validationSchema.validate(hcData, { abortEarly: false });
        setHCError(false);
        const entries = Object.entries(headcount);
        const indexToUpdate = hc_index;
        if (indexToUpdate >= 0 && indexToUpdate < entries.length) {
          entries[indexToUpdate][1] = +hc; // Updating the value to 30, modify as needed
        }
        const updatedObject = Object.fromEntries(entries);
        //console.log(updatedObject)
        var link = baseURL + "updateUserHeadcount/"+user_id;
    axios
      .put(link,updatedObject,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        //console.log(response)
        setHeadcount(updatedObject)
        setLoader(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoader(false);
      });
       
        handleClose();
     }
      catch{
        setHCError(true);
        setLoader(false);
      }
      
    }

return(
    <>
    {loader?
      <div id="loader-container">
        <div id="loader">
          <RotatingLines
            strokeColor="#0072B2"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      </div> :<>
    <div className="titlename">
        Headcount Growth
    </div>
    <DataTable
            title={<div className="managementTableTitle">Headcount Growth List</div>}
            columns={columnDefs}
            data={rowData}
            fixedHeader
            responsive
            fixedHeaderScrollHeight="700px"
            className='managementTable'
            />
    <Modal className="managementmodal" centered show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontFamily:'Poppins-Medium'}}>Add Headcount Growth</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
              <label className="modalbodylabel">Year</label>
              <input className="form-control modalbodyinput" value={year_hc} disabled/>
              <label className="modalbodylabel">Headcount Growth</label>
              <input className={`form-control modalbodyinput ${hcError?'errorBorder':''}`} value={hc} onChange={(e:any)=>onCountChange(e)}/>
              {hcError?<label className="modalbodylabel" style={{color:'#FE8485'}}>Please enter valid count</label>:null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" style={{fontFamily:'Poppins-Medium'}} onClick={handleClose}>
          Cancel
        </Button>
        <Button className="submit" style={{fontFamily:'Poppins-Medium'}} onClick={()=>{onSubmitData()}}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
    </>}
    </>
)
}

export default Headcountgrowth;