import React, { useState, useEffect, useCallback } from "react";
import '../CSS/common.css';
import Dialog from '@mui/material/Dialog';
import axios from "axios";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PiInfoFill } from "react-icons/pi";
import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { baseURL, clinetidsub } from "./Apis";
import { encryptor } from "./Encryption";
import crown from '../Images/crown.svg';
import DataTable from 'react-data-table-component';

const Subscription=()=>{


  const columns:any = [
    {

      selector: (row:any) => row.col1,
      width:'400px',
      cell:(row:any, rowIndex:any) => (
        <div style={{ color: 'white', height: '100%',width:'100%', display: 'flex', alignItems: 'center',paddingLeft:'40px', paddingRight:'40px', justifyContent:'end'  }}>
          {row.col1}
        </div>
      ),
    },
    {
      name: <div style={{ display: "flex", flexDirection: "column", alignItems:'start',padding:'40px' }}>
      <div>
        <label style={{fontWeight:500,fontSize:'20px',color:'#25282B'}}>Freemium</label>
      </div>
      <div>
        <label style={{fontWeight:500,fontSize:'32px',color:'#25282B'}}>Free</label>
      </div>
    </div>,
      selector: (row:any) => row.col2,
      width:'200px',
      cell: (row:any, rowIndex:any) => (
        <div style={{ backgroundColor: rowIndex % 2 === 0 ? 'rgba(0, 114, 178, 0.1)' : '#ffffff', height: '100%',width:'100%', display: 'flex', alignItems: 'center',paddingLeft:'40px',color:'#0072B2' }}>
        {row.col2}
      </div>
      ),
    },
    {
      name: <div style={{ display: "flex", flexDirection: "column", alignItems: 'start', backgroundColor:'#0072B2', height: '100%',width:'100%',padding:'40px' }}>
      <div>
        <label style={{ fontWeight: 700, fontSize: '20px', color: '#FFE661' }}>Premium</label>
      </div>
      <div>
        <label style={{ fontWeight: 700, fontSize: '32px', color: '#FFE661' }}>$49.00</label>
      </div>
    </div>,
      selector: (row:any) => row.col3,
      width:'200px',
      cell:(row:any, rowIndex:any) => (
        <div style={{  color: 'white', height: '100%',width:'100%', display: 'flex', alignItems: 'center',paddingLeft:'40px' }}>
          {row.col3}
        </div>
      ),
    },
  ];
  
  // Define the data
  const data:any = [
    { id: 1, col1: 'Timebound access', col2: '15 Days', col3: 'Monthly' },
    { id: 2, col1: 'Maximum physical sites', col2: '5', col3: 'Unlimited' },
    { id: 3, col1: 'Maximum number of square feet', col2: '10,000', col3: 'Unlimited' },
    { id: 4, col1: 'Bulk upload', col2: 'No', col3: 'Yes' }
  ];
  const [sec, setSec]=useState(1);
  //const stripePromise = loadStripe("pk_live_51ONNdDCKuawT4IsjQRpUnn7dwJgLUplPwPI81d8bjg8iOtqRsM6bnSIyLdUI8ot6A3i8yAVWEikrWLdLnSj6DtTw007fPGWYix");
  const stripePromise = loadStripe("pk_test_51ONNdDCKuawT4IsjswPnwDM1wPRDhNhtdKsmoPJNk2opwBr7iZw3kpsRqAzdQbofjwowfl8p4WzFAKV9rnuFbjdF00xy3uAC9V");
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  const handleShowCheckoutForm = () => {
    setSec(2)
    setShowCheckoutForm(true);
  };
  const CheckoutForm = () => {
    const fetchClientSecret = useCallback(() => {
      // Create a Checkout Session
      return fetch(baseURL+"stripe/create-checkout-sessionn", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // Include the token as a bearer token
        },
      })
        .then((res) => res.json())
        .then((data) => data.clientSecret);
    }, []);
   
    const options = {fetchClientSecret};
   


    return (
      <div id="checkout">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    )
  }



  
  const authTokenTest =   sessionStorage.getItem("apiToken") === undefined? 0: sessionStorage.getItem("apiToken");   
  const authToken =encryptor.decryptData(authTokenTest,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');
  const [url, setUrl] = useState('');
  var subscribedFlag:any=sessionStorage.getItem('subscribedFlag')=== undefined ?'false':sessionStorage.getItem('subscribedFlag');
  const [modalShow, setModalShow] = React.useState(false);
  const [message,setMessage]= useState('');
    const initialOptions:any = {
        "client-id": clinetidsub,
        "enable-funding": "paylater,card",
        "disable-funding": "",
        "data-sdk-integration-source": "integrationbuilder_sc",
        vault: "true",
        intent: "subscription",
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            const urlParams = new URLSearchParams(window.location.search);
            const subscriptionId = urlParams.get("session_id");

            // Check if subscriptionId exists and is not null or blank
            if (subscriptionId && subscriptionId.trim() !== "") {
              const response: any =await axios.get(`${baseURL}stripe-session`, {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              });
              //console.log(response);
              if (response.data.message === "User subscribed successfully") {
                sessionStorage.setItem("subscribedFlag", "true");
                sessionStorage.setItem("userAccessRank", "1");
                setMessage(`You have successfully subscribed to the plan.`);
                setModalShow(true);
              } else {
                setMessage(
                  `Failed to activate the subscription. Please try again.`
                );
                setModalShow(true);
              }
            }
          } catch (error) {
            //console.error("Error occurred:", error);
          }
        };
        fetchData();
      }, []);
     

    
    
return (
  <>
 
    {subscribedFlag==='true'?
    <>
     <div className="titlename">Subscription</div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop:"100px",
        marginBottom:'50px'
      }}
    >
      <div>
        <label style={{ fontWeight: "400", fontSize: "45px"}}>
          Your&nbsp;
        </label>
        <label style={{ fontWeight: "700", fontSize: "45px" }}>
          Current&nbsp;
        </label>
        <label style={{ fontWeight: "400", fontSize: "45px"}}>
          Plan!
        </label>
      </div>
    </div>
    <div className="card subscribedCard" style={{backgroundColor:'#0072B2', border:'none', borderRadius:'12px',paddingTop:'20px', paddingBottom:'20px'}}>
        <div className="card-body">
          <div className="cardHeader" >
            <div>
              <div className="row" >
                <div className="col-3" >
                <label style={{fontWeight:500,fontSize:'20px',color:'#FFE661'}}>Premium</label>
              </div>
              <div className="col-3">
                <label style={{fontWeight:500,fontSize:'20px',color:'#FFF'}}>Subscription period</label>
              </div>
              <div className="col-3">
              <label style={{fontWeight:500,fontSize:'20px',color:'#FFF'}}>Maximum physical site</label>
              </div>
              <div className="col-3">
              <label style={{fontWeight:500,fontSize:'20px',color:'#FFF'}}>Maximum square feet</label>
              </div>
              </div>
              <div className="row">
                <div className="col-3">
                <label style={{fontWeight:500,fontSize:'32px',color:'#FFE661'}}>$49.00</label>
              </div>
              <div className="col-3">
                <label style={{fontWeight:500,fontSize:'32px',color:'#FFF'}}>Monthly</label>
              </div>
              <div className="col-3">
              <label style={{fontWeight:500,fontSize:'32px',color:'#FFF'}}>Unlimited</label>
              </div>
              <div className="col-3">
              <label style={{fontWeight:500,fontSize:'32px',color:'#FFF'}}>Unlimited</label>
              </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>


    </>:<>
    {sec===1?<>
      <div className="titlename">Subscription</div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop:"50px"
      }}
    >
     {/*  <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #0072B2',
  backgroundColor: 'rgba(0, 114, 178, 0.08)',
  borderRadius: '10px',
  padding:'15px',
 marginBottom:'20px'
}}>
  <PiInfoFill style={{ fontSize: '20px', color: '#0072B2' }}/> 
  <span style={{ marginLeft: '10px',color: '#0072B2',fontStyle:'italic' , fontSize:'14px', fontWeight:400, fontFamily:'Poppins-Regular' }}>
    Subscription available only for the US region currently.
  </span>
</div> */}
      <div>
        <label style={{ fontWeight: "700", fontSize: "45px" }}>
          Choose&nbsp;
        </label>
        <label style={{ fontWeight: "400", fontSize: "45px" }}>
          your plan!
        </label>
      </div>
      <div style={{ fontWeight: "400", fontSize: "18px" }}>
        Start a 15-day free trial. No contract or credit card required.
      </div>
    </div>

   
  {/*  OLD DESIGN FOR SUBSCRIPTION CARDS 
   <div className="subscriptionCard" style={{ display: 'flex', gap: '0px' }}>

      <div className="card card1" >
        <div className="card-body">
          <div
            className="cardHeader"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          ></div>
          <p className="card-text" >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <div className="cardtext1">
                <label>Timebound access</label>
              </div>
              <div className="cardtext2">
                <label>Maximum physical site</label>
              </div>
              <div className="cardtext3">
                <label>Number of square feet</label>
              </div>
              <div className="cardtext3">
                <label>Bulk Upload</label>
              </div>
            </div>
          </p>
        </div>
      </div>

      <div className="card card2">
        <div className="card-body">
          <div className="cardHeader">
            <div style={{ display: "flex", flexDirection: "column", alignItems:'start' }}>
              <div>
                <label style={{fontWeight:500,fontSize:'20px',color:'#25282B'}}>Freemium</label>
              </div>
              <div>
                <label style={{fontWeight:500,fontSize:'32px',color:'#25282B'}}>Free</label>
              </div>
            </div>
          </div>
          <p className="card-text" >
            <div style={{ display: "flex", flexDirection: "column" ,alignItems:'start' }}>
              <div className="cardtext1" >
                <label>15 days</label>
              </div>
              <div className="cardtext2">
                <label>5</label>
              </div>
              <div className="cardtext3">
                <label>10,000</label>
              </div>
              <div className="cardtext3">
                <label>No</label>
              </div>
            </div>
          </p>
        </div>
      </div>

      <div className="card card3" style={{ position: 'relative' }}>
  <div className="card-body">
    <div className="cardHeader">
      <div style={{ display: "flex", flexDirection: "column", alignItems: 'start' }}>
        <div>
          <label style={{ fontWeight: 700, fontSize: '20px', color: '#FFE661' }}>Premium</label>
        </div>
        <div>
          <label style={{ fontWeight: 700, fontSize: '32px', color: '#FFE661' }}>$49.00</label>
        </div>
        <div>
          <label style={{ fontWeight: 700, fontSize: '16px', color: '#FFE661' }}>per month</label>
        </div>
      </div>
    </div>
    <p className="card-text">
      <div style={{ display: "flex", flexDirection: "column", alignItems: 'start' }}>
        <div className="cardtext1">
          <label>Monthly</label>
        </div>
        <div className="cardtext2">
          <label>Unlimited</label>
        </div>
        <div className="cardtext3">
          <label>Unlimited</label>
        </div>
        <div className="cardtext3">
                <label>Yes</label>
              </div>
      </div>
    </p>
  </div>
  <div style={{ position: 'absolute', top: '-35px', right: '-35px' }}>
    <img src={crown} alt="Crown" style={{ width: '70px', height: '70px' }} />
  </div>
</div>

    </div> 
 */}


<DataTable
    className="subscriptionTable mt-5"
    columns={columns}
    data={data}
    customStyles={{
      tableWrapper: {
        style: {
          display: 'inline-block',
          width: 'fit-content',
          overflow: 'visible', // Allow overflow to be visible
        },
      },
      cells: {
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    }}
  />



 





    <button className="stripebutton btn btn-block col-4 mt-3 rounded-pill" onClick={handleShowCheckoutForm}>Stripe</button>

    </>:<>
    <div style={{ flex: "1", textAlign: "left" }}>
                <i className="fa fa-arrow-circle-o-left fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"20px" }} onClick={()=>{setSec(1)}}></i>
                <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"20px",color: "#0072B2"}} onClick={()=>{setSec(1)}}>&nbsp;Stripe Payment</label>
              </div>
    <CheckoutForm />
    </>}
     
     
    <Dialog
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          onClick: () => { }, // Disable backdrop click
        }}
        PaperProps={{
          style: {
            backgroundColor:'#fff',
            textAlign: 'center',
            justifyContent:'center',
            position: 'absolute',
            top: '10%', // Adjust the distance from the top as needed
            },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontFamily:'Poppins-Medium',
            color:'#fff'}}>
            {message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>}
  </>
);
}

export default Subscription;